import { ComponentPropsWithoutRef } from 'react';
import { Flex } from '@lunarway/matter-web';
import styled, { css } from 'styled-components';

export const AuthSigninIllustration = (
  props: ComponentPropsWithoutRef<'div'>
) => (
  <Flex flexDirection="column" gap={200} {...props}>
    <StyledAuthSigninIllustration
      src="/assets/images/auth/AuthSigninIllustrationImage.png"
      alt="Send money interface"
    />
  </Flex>
);

const StyledAuthSigninIllustration = styled.img(
  ({ theme }) => css`
    width: 100%;
    border-radius: ${theme.tokens.radii[100]};
    box-shadow: ${theme.shadows.medium};
  `
);
