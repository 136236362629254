import { ReactNode, useState } from 'react';
import { ComboBox, ComboBoxOption, Text } from '@lunarway/matter-web';
import styled, { css } from 'styled-components';

export enum SigninCountryType {
  Denmark = 'Denmark',
  Sweden = 'Sweden',
  Norway = 'Norway',
}

interface AuthSigninCountrySelectorProps {
  onCountryChange: (selected: SigninCountryType) => void;
  activeCountry: SigninCountryType;
}

export const AuthSigninCountrySelector = ({
  onCountryChange,
  activeCountry,
}: AuthSigninCountrySelectorProps) => {
  const countryOptions: Array<SigninCountryType> = [
    SigninCountryType.Denmark,
    SigninCountryType.Sweden,
    SigninCountryType.Norway,
  ];
  const [country, setCountry] = useState<string>(activeCountry);

  const countryAdornments: Record<string, string> = {
    Denmark: '/assets/images/flags/dk.png',
    Sweden: '/assets/images/flags/sv.png',
    Norway: '/assets/images/flags/no.png',
  };

  const selectedCountryAdornment = countryAdornments[country];

  const adornmentStart: ReactNode = (
    <StyledImg src={selectedCountryAdornment} alt={country} />
  );

  const handleCountryChange = (selectedCountry: SigninCountryType) => {
    setCountry(selectedCountry);
    onCountryChange(selectedCountry);
  };

  return (
    <StyledComboBox
      value={country}
      isSearchable={false}
      onChange={(selectedCountry: string) =>
        handleCountryChange(selectedCountry as SigninCountryType)
      }
      data-test-id="auth-signin-country-selector"
      showErrorAdornment={false}
      adornmentStart={adornmentStart}
    >
      {countryOptions.map((option) => (
        <ComboBoxOption key={option} value={option}>
          <Text data-test-id="auth-signin-country-selector-option">
            {option}
          </Text>
        </ComboBoxOption>
      ))}
    </StyledComboBox>
  );
};

const StyledComboBox = styled(ComboBox)`
  width: 100%;
  max-width: 200px;
`;

const StyledImg = styled.img(
  ({ theme }) => css`
    width: ${theme.tokens.spaces[300]};
    margin-inline-start: ${theme.tokens.spaces[150]};
    border-radius: ${theme.tokens.radii[999]};
  `
);
