import type {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from 'next';
import { getProviders } from 'next-auth/react';
import { getServerSession } from 'next-auth/next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useSearchParams } from 'next/navigation';

import { AuthLayout } from '_src/app/auth/AuthLayout';
import { AuthSigninView } from '_src/app/auth/AuthSigninView';

// eslint-disable-next-line boundaries/element-types
import { authOptions } from '../api/auth/[...nextauth]';

const Page = ({
  providers,
  ipCountryCode,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const formattedProviders = Object.values(providers ?? []);
  const searchParams = useSearchParams();
  const countryCode = searchParams?.get('country') || ipCountryCode;

  return (
    <AuthSigninView countryCode={countryCode} providers={formattedProviders} />
  );
};

Page.getLayout = () => AuthLayout;

export default Page;

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const callbackUrl = context.query.callbackUrl as string;
  const session = await getServerSession(context.req, context.res, authOptions);
  const countryCode =
    (context.req.headers['x-vercel-ip-country'] as string) ?? 'dk';

  const destination = callbackUrl ? `/?callbackUrl=${callbackUrl}` : '/';

  if (session) {
    return {
      redirect: {
        destination,
      },
    };
  }

  const providers = await getProviders();

  return {
    props: {
      ipCountryCode: countryCode.toLowerCase(),
      providers: providers ?? [],
      ...(await serverSideTranslations(context.locale as string, [
        'auth',
        'notification',
        'common',
      ])),
    },
  };
};
