import { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'next-i18next';
import { ClientSafeProvider } from 'next-auth/react';
import { Flex, Heading, Paragraph } from '@lunarway/matter-web';
import styled from 'styled-components';

import { AuthSigninSelectorItem } from './AuthSigninSelectorItem';
import { SigninCountryType } from './AuthSigninCountrySelector';

type AuthSigninSelectorProps = ComponentPropsWithoutRef<'div'> & {
  providers: Array<ClientSafeProvider>;
  selectedCountry: SigninCountryType;
};

const dkProviders = ['business', 'lunar-eid'];
const seProviders = ['business', 'lunar-eid'];
const noProviders = ['business', 'lunar-eid'];

export const AuthSigninSelector = ({
  providers,
  selectedCountry,
  ...otherProps
}: AuthSigninSelectorProps) => {
  const { t } = useTranslation('auth');
  const providerList = getProviderListByCountry(selectedCountry);

  const filteredProviders = Object.values(providers).filter((provider) =>
    providerList.includes(provider.id)
  );

  return (
    <StyledFlex
      flexDirection="column"
      justifyContent="center"
      gap={500}
      {...otherProps}
    >
      <Flex flexDirection="column" gap={200}>
        <StyledHeading type="heading">{t('auth.signin.title')}</StyledHeading>
        <Paragraph>{t('auth.signin.description')}</Paragraph>
      </Flex>
      <Flex flexDirection="column" gap={200}>
        {filteredProviders.map((provider) => (
          <AuthSigninSelectorItem
            key={provider.name}
            country={selectedCountry}
            provider={provider}
          />
        ))}
      </Flex>
    </StyledFlex>
  );
};

const getProviderListByCountry = (country: SigninCountryType) => {
  switch (country) {
    case SigninCountryType.Sweden:
      return seProviders;
    case SigninCountryType.Norway:
      return noProviders;
    default:
      return dkProviders;
  }
};

const StyledHeading = styled(Heading)`
  width: 350px;
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;
