import { signIn, ClientSafeProvider } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import {
  Avatar,
  Button,
  ChevronRightIcon,
  Flex,
  Text,
} from '@lunarway/matter-web';
import MitIDLogo from 'public/assets/images/brands/MitID.svg';
import LunarLogo from 'public/assets/images/brands/Lunar.svg';
import BankIdSeLogo from 'public/assets/images/brands/BankIdSe.svg';
import BankIdNoLogo from 'public/assets/images/brands/BankidNo.svg';
import styled, { css } from 'styled-components';
import Cookies from 'js-cookie';

import { SigninCountryType } from '_src/app/auth/AuthSigninCountrySelector';

export enum AuthEidSubProvider {
  bankIdSe = 'bankidse',
  bankIdNo = 'bankidno',
  mitId = 'mitid',
  mitIdErhverv = 'mitid_erhverv', // To be used in later release
}

export type AuthSigninSelectorItemProps = {
  provider: ClientSafeProvider;
  country: SigninCountryType;
};

const isMock = process.env.NEXT_PUBLIC_API_MOCKING === 'enabled';
const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? 'lunar.app';
const TERRA_LOGIN_COOKIE = 'TERRA_LOGIN_TYPE';

export const AuthSigninSelectorItem = ({
  provider,
  country,
}: AuthSigninSelectorItemProps) => {
  const { t } = useTranslation('auth');
  const lunarBusiness = provider.id === 'business';

  const handleProviderSelected = async () => {
    try {
      Cookies.set(TERRA_LOGIN_COOKIE, provider.id, {
        expires: 7, // Safari and Brave limits this to 7 days https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#why-is-my-expiration-time-capped-at-7-days-or-24-hours
        domain: isMock ? 'localhost' : cookieDomain,
        path: '/',
      });
    } catch (err) {
      // dont care
    }

    let authParams = '';
    if (provider.id === 'lunar-eid') {
      const countryParam = getCountryParam(country);
      const subEidProvider = getSubProviderParam(country);
      authParams = `provider=${subEidProvider}&country=${countryParam}`;
    }

    await signIn(provider.id, {}, authParams);
  };

  return (
    <StyledButton onClick={handleProviderSelected}>
      <Avatar size={500} variant="rounded">
        {lunarBusiness ? <LunarLogo /> : getEidLogo(country)}
      </Avatar>
      <Flex flexDirection="column" alignItems="flex-start" gap={50}>
        <Text>
          {t('auth.signin.action', {
            provider:
              provider.id === 'lunar-eid'
                ? getProvider(country)
                : provider.name,
          })}
        </Text>
        {lunarBusiness && (
          <Text fontSize="small" variant="secondary">
            {t('auth.signin.hint')}
          </Text>
        )}
      </Flex>
      <StyledChevron size="small" variant="surfaceQuaternary" />
    </StyledButton>
  );
};

const getProvider = (country: SigninCountryType) => {
  switch (country) {
    case SigninCountryType.Sweden:
    case SigninCountryType.Norway:
      return 'BankID';
    default:
      return 'MitID';
  }
};

const getEidLogo = (country: SigninCountryType) => {
  switch (country) {
    case SigninCountryType.Sweden:
      return <BankIdSeLogo />;
    case SigninCountryType.Norway:
      return <BankIdNoLogo />;
    default:
      return <MitIDLogo />;
  }
};

const getCountryParam = (country: SigninCountryType) => {
  switch (country) {
    case SigninCountryType.Norway:
      return 'no';
    case SigninCountryType.Sweden:
      return 'se';
    default:
      return 'dk';
  }
};

const getSubProviderParam = (country: SigninCountryType) => {
  switch (country) {
    case SigninCountryType.Norway:
      return AuthEidSubProvider.bankIdNo;
    case SigninCountryType.Sweden:
      return AuthEidSubProvider.bankIdSe;
    default:
      return AuthEidSubProvider.mitId;
  }
};

const StyledButton = styled(Button)(
  ({ theme }) => css`
    height: auto;
    gap: ${theme.tokens.spaces[200]};
    color: ${theme.textPrimary};
    background: ${theme.backgroundSecondary};
    padding: ${theme.tokens.spaces[150]};
    border-radius: ${theme.tokens.radii[150]};

    @media (hover: hover) and (pointer: fine) {
      &:hover:not(:disabled) {
        background: ${theme.interaction.hovered};
      }
    }
  `
);

const StyledChevron = styled(ChevronRightIcon)`
  margin-inline-start: auto;
`;
