import { useState } from 'react';
import { ClientSafeProvider } from 'next-auth/react';
import styled, { css } from 'styled-components';
import { Flex } from '@lunarway/matter-web';

import { AuthSigninSelector } from './AuthSigninSelector';
import { AuthSigninIllustration } from './AuthSigninIllustration';
import { AuthSigninReturnButton } from './AuthSigninReturnButton';
import {
  AuthSigninCountrySelector,
  SigninCountryType,
} from './AuthSigninCountrySelector';

export type AuthSigninViewProps = {
  providers: Array<ClientSafeProvider>;
  countryCode: string;
};

export const AuthSigninView = ({
  providers,
  countryCode,
}: AuthSigninViewProps) => {
  const [selectedCountry, setSelectedCountry] = useState(
    countryCodeToSignInType(countryCode)
  );

  const handleCountryChange = (activeCountry: SigninCountryType) => {
    setSelectedCountry(activeCountry);
  };

  return (
    <StyledView flexDirection="column" gap={200}>
      <AuthSigninReturnButton />
      <Flex gap={500} justifyContent="center" alignItems="center" flexGrow={1}>
        <Flex flexDirection="column" gap={500}>
          <AuthSigninCountrySelector
            activeCountry={selectedCountry}
            onCountryChange={handleCountryChange}
          />
          <StyledSelector
            providers={providers}
            selectedCountry={selectedCountry}
          />
        </Flex>
        <StyledIllustration />
      </Flex>
    </StyledView>
  );
};

const countryCodeToSignInType = (code: string): SigninCountryType => {
  switch (code) {
    case 'no':
      return SigninCountryType.Norway;
    case 'se':
      return SigninCountryType.Sweden;
    default:
      return SigninCountryType.Denmark;
  }
};

const StyledView = styled(Flex)(
  ({ theme }) => css`
    height: 100vh;
    padding: ${theme.tokens.spaces[500]} ${theme.tokens.spaces[1000]};
  `
);

const StyledSelector = styled(AuthSigninSelector)`
  max-width: 400px;
`;

const StyledIllustration = styled(AuthSigninIllustration)`
  max-width: 840px;
  width: 100%;

  @media (max-width: 1280px) {
    display: none;
  }
`;
