import { ArrowLeftIcon, Button } from '@lunarway/matter-web';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

export const AuthSigninReturnButton = () => {
  const { t } = useTranslation('auth');

  return (
    <StyledButton
      variant="secondary"
      adornmentStart={<ArrowLeftIcon size="small" />}
      onClick={() => window.location.assign('https://lunar.app/business')}
    >
      {t('auth.signin.return')}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  align-self: start;
  text-decoration: none;
`;
